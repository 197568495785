<template>
  <iq-card>
    <template v:slot="body">
      <!-- 捐款金额 -->
      <div class="common-part amount-box">
        <h3>Select an amount</h3>
        <ul class="btn-select m-0 p-0 three-btn">
          <li class="self-r" :class="{ outlineBlue: activeLi==1 }" @click="activeLi=1">
            $100
            <input type="radio" v-model="amount" name="some-radios" value="100" class="cursor-pointer" />
          </li>
          <li class="self-r" :class="{ outlineBlue: activeLi==2 }" @click="activeLi=2">
            $500
            <input type="radio" v-model="amount" name="some-radios" value="500" class="cursor-pointer" />
          </li>
          <li class="self-r" :class="{ outlineBlue: activeLi==3 }" @click="activeLi=3">
            $1000
            <input type="radio" v-model="amount" name="some-radios" value="1000" class="cursor-pointer" />
          </li>
          <li class="self-r" :class="{ outlineBlue: activeLi==4 }" @click="activeLi=4">
            $5000
            <input type="radio" v-model="amount" name="some-radios" value="5000" class="cursor-pointer" />
          </li>
          <li class="self-r" :class="{ outlineBlue: activeLi==5 }" @click="activeLi=5">
            $10000
            <input type="radio" v-model="amount" name="some-radios" value="10000" class="cursor-pointer" />
          </li>
          <li class="self-r" :class="{ outlineBlue: activeLi==6 }" @click="activeLi=6">
            $20000
            <input type="radio" v-model="amount" name="some-radios" value="20000" class="cursor-pointer" />
          </li>
          <li :class="{outlineBlue:activeLi==7}" @click="activeLi=7">
            Other
            <input type="radio" v-model="amount" name="some-radios" value="" class="cursor-pointer" />
          </li>
        </ul>
        <div class="d-flex align-item-center self_pre_input mt-4">
          <span>USD</span>
          <!-- <input type="number" class="form-control" v-model="amount" /> -->
          <el-input v-model="amount" @input="(value) => {limitInput(value)}" :disabled="activeLi!=7"/>
        </div>
        <!-- <p class="choosed-result">
          Reference price: ≈ HKD{{ parseInt(amount * 7.7516) }}
        </p> -->
      </div>
    </template>
  </iq-card>
</template>

<script>
  export default {
    data() {
      return {
        amount: "",
        activeLi: 0
      };
    },

    components: {},

    created() { },
    computed: {},
    watch: {
      amount() {
        this.$emit('getAmountFunc', this.amount)
        this.$bus.$emit('getAmountDon', this.amount)
      }
    },
    methods: {
      //限制金额
      limitInput(value, item) {
        if (/[^0-9.]/g.test(value)) {
          this.$message.warning("Only numbers can be entered")
        }
        this.amount = value.replace(/[^0-9.]/g, "");
      },
    }
  }

</script>
<style lang="scss" scoped>
  .outlineBlue {
    color: #50b5ff;
    border: 1px solid #50b5ff !important;
    background-color: #f5fbff;
  }

  .self_pre_input {
    >span {
      display: block;
      width: 122px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      background: #f8f8f8;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
    }

    ::v-deep .form-control {
      border-radius: 0;
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
    }

    ::v-deep .el-input__inner {
      border-radius: 0 5px 5px 0;
      height: 45px;
      line-height: 45px;
    }
  }

  @media (min-width: 768px) {
    .common-part {
      padding: 20px;

      >h3 {
        font-size: 16px;
        color: #333;
        font-weight: 500;
        margin-bottom: 26px;
      }

      >.btn-select {
        display: flex;
        margin-top: 26px;

        .outlineBule {
          border-color: #50b5ff;
          background-color: #50b5ff;
        }

        >li {
          width: 315px;
          height: 45px;
          border: 1px solid #f1f1f1;
          border-radius: 10px;
          text-align: center;
          line-height: 45px;
          position: relative;
          cursor: pointer;

          &.self-r {
            margin-right: 20px;
          }

          >input {
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .choosed-result {
        padding-top: 30px;
      }
    }
  }

  @media (max-width: 768px) {
    .common-part {
      padding: 20px;

      >h3 {
        font-size: 16px;
        color: #333;
        font-weight: 500;
        margin-bottom: 26px;
      }

      >.btn-select {
        margin-top: 26px;

        >li {
          width: 100%;
          height: 45px;
          border: 1px solid #f1f1f1;
          border-radius: 10px;
          text-align: center;
          line-height: 45px;
          position: relative;
          margin: 5px 0;

          &.self-r {
            margin-right: 20px;
          }

          >input {
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      >.three-btn {
        display: flex;
        flex-wrap: wrap;

        >li {
          width: calc(33.3333% - 20px);
        }
      }

      .choosed-result {
        padding-top: 30px;
      }
    }
  }
</style>